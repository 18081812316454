.carousel.carousel-slider .control-arrow:before {

  color: black !important;
}

.carousel.carousel-slider {
    margin: auto;
    overflow: inherit;
}

.carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover{
    background-color: transparent;
    /* right: -146px; */
}

.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    /* left: -146px; */
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
    opacity: 1;
}

.carousel .control-next.control-arrow:before {
    content: '';
    border: solid gray;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
    content: '';
    border: solid gray;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.85) !important;
}